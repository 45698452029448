import React from 'react';
import './ReviewCard.css';
import { StarFilled } from '@ant-design/icons';

class ReviewCard extends React.Component {
    render() {
        const numStars = this.props.card?.Stars || 0;
        const stars = [];
        for (let i = 0; i < numStars; i++) {
            stars.push(i);
        }
        return (
            <div className="ReviewCard-Div">
                {/*
                <img src={this.props.card?.SRC} alt={this.props.card?.ALT} className="ReviewCard-Image" />
                */}
                <div className="ReviewCard-Info-Div">
                    <p className="ReviewCard-Review">"{this.props.card?.Review}"</p>
                    <p>- {this.props.card?.Reviewer}</p>
                    <div className="ReviewCard-Stars">
                        {
                            stars.map(() => (
                                <StarFilled className="ReviewCard-Star" />
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ReviewCard;