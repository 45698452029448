import React from 'react';
import './Footer.css';
import { Row, Col, Divider, Button } from 'antd';
import { SocialIcon } from "react-social-icons"

class Footer extends React.Component {
    render() {
        return (
            <Row justify="center" gutter={[0,0]}>
                <Col xs={24}>
                    <Divider />
                </Col>
                <Col xs={23} sm={22}>
                    <Row justify="space-between">
                    <Col xs={24} sm={8}>
                        <div className="Footer-Link-Div">
                            <h1>Jiana Alnajjar</h1>
                        </div>
                        <div className="Footer-Link-Div">
                        <a href="mailto:jiforcefitness@gmail.com" className="Footer-Anchor-Link">jiforcefitness@gmail.com</a>
                        </div>
                        <div className="Footer-Link-Div">
                            <a href="https://www.acefitness.org/about-ace/">
                            <img className="Ace--Logo" src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Face.svg?alt=media&token=ee770f5e-1c76-4573-bea1-fd123e8fd2e5" alt="Ace Personal Training Certification" />
                            </a>
                        </div>
                        <div className="Footer-Link-Div">
                        <SocialIcon url="https://www.instagram.com/jiforcefitness/" />
                        </div>
                    </Col>
                    <Col xs={0} sm={8}>
                        <Row>
                            <Col xs={24}>
                                <h1>Quick Links</h1>
                            </Col>
                        </Row>
                        <Row>
                        <Col xs={24} sm={12}>
                            <div className="Footer-Link-Div">
                            <a href="#my-services" className="Footer-Anchor-Link">My Services</a>
                            </div>
                            <div className="Footer-Link-Div">
                            <a href="#about-me" className="Footer-Anchor-Link">About Me</a>
                            </div>
                            <div className="Footer-Link-Div">
                            <a href="https://app.jiforcefitness.com/live-classes" className="Footer-Anchor-Link">Events</a>
                            </div>
                        </Col>
                        <Col xs={24} sm={12}>
                            <div className="Footer-Link-Div">
                            <a href="#testimonials" className="Footer-Anchor-Link">Testimonials</a>
                            </div>
                            <div className="Footer-Link-Div">
                            <a href="#contact-me" className="Footer-Anchor-Link">Contact Me</a>
                            </div>
                        </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={6}>
                        <Row justify="end" gutter={[10,10]} align="middle">
                            <Col xs={24} className="Footer-Button">
                                <a href="/#contact-me"><Button block className="Button-Red" size="large">Contact Me</Button></a>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Footer;