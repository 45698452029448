import React from 'react';
import './ServiceCard.css';
import { Row, Col, Button } from 'antd';

class ServiceCard extends React.Component {
    render() {
        return (
            <Row justify="space-around" align="middle" className="ServiceCard-Row">
                <Col xs={24} sm={13}>
                    <img src={this.props.service?.SRC} alt={this.props.service?.ALT} className="ServiceCard-Image" />
                </Col>
                <Col xs={24} sm={10}>
                    <h2 className="ServiceCard-Title">{this.props.service?.Title}</h2>
                    <h3 className="ServiceCard-SubTitle">{this.props.service?.SubTitle}</h3>
                    <p className="ServiceCard-Description">{this.props.service?.Description}</p>
                    {/*
                    <a href={this.props.service?.ButtonLink}><Button className="Button-Red" size="large">Book Now</Button></a>
                     */}
                </Col>
            </Row>
        )
    }
}

export default ServiceCard;