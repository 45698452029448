import React from 'react';
import './Banner.css';
import { Row, Col, Input, Button, message, notification } from 'antd';
import { Navbar, } from '../index';
import { ENVIRONMENT } from '../../constants';
import axios from 'axios';

class Banner extends React.Component {
    state = {
        Email: '',
        VideoControls: false,
    }

    componentDidMount() {
        try {
            const promise = document.querySelector("video").play();
            if (promise !== undefined) {
                promise.catch(error => {
                    // Auto Play Prevented
                    this.setState({
                        VideoControls: true,
                    })
                }).then(() => {
                    // Auto-play started
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    validateEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
        }
        message.error("Please enter a valid email.")
        return (false)
    }

    submitContact = async (trainerEmail) => {
        const emailValidate = this.validateEmail(this.state.Email);
        if (!emailValidate) return;
        const data = {
            name: "(Banner Contact)",
            email: this.state.Email,
            phone: "(Banner Contact)",
            message: "(Banner Contact)",
            TrainerEmail: trainerEmail,
        }

        const sendEmail = await axios.post(`${ENVIRONMENT.BACKEND_URL}/custom-website/contact`, data);
        if (sendEmail.data.error) {
            notification.error({
                message: "Something went wrong!",
                description: `Sorry we couldn't send you message, please send me an email at ${trainerEmail}`,
                duration: 0,
                placement: "bottomRight",
            })
        } else {
            notification.success({
                message: "Success",
                description: "Thanks for your message, I'll be in contact with you soon.",
                placement: "bottomRight",
            })
        }
    }


    render() {
        return (
            <Row>
            <Col xs={24}>
            <div className="Banner-Center">
                <header className="Banner-Header">
                    {
                        this.state.VideoControls ?
                        <video className="Banner-Video" id="Banner-Video" autoPlay muted loop playsInline>
                            <source
                                src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-video-final.mp4?alt=media&token=5d47739c-2ccc-4598-8d5d-c4b7cf24148e"
                                type="video/mp4"
                            />
                        </video>
                        :
                        <video className="Banner-Video" id="Banner-Video" autoPlay muted loop>
                            <source
                                src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-video-final.mp4?alt=media&token=5d47739c-2ccc-4598-8d5d-c4b7cf24148e"
                                type="video/mp4"
                            />
                        </video>
                    }
                    <section className="Banner-Navbar">
                        <Navbar />
                    </section>
                    <section className="Banner-Header-Text">
                        <Row justify="center">
                            <Col xs={24}>
                            <h1 className="Banner-Title">JIFORCE FITNESS</h1>
                            </Col>
                            <Col xs={24}>
                                <Row justify="center" gutter={[20,20]} className="Banner-Email-Submit">
                                    <Col xs={24} sm={14}>
                                        <Input placeholder="E-mail." size="large" className="Banner-Input" value={this.state.Email} onChange={(e) => this.handleChange("Email", e.target.value)} />
                                    </Col>
                                    <Col xs={24} sm={6}>
                                        <Button className="Button-Red" size="large" block onClick={() => this.submitContact("jiforcefitness@gmail.com")}>Subscribe</Button>
                                    </Col>
                                    <Col xs={24}>
                                    <div className="Banner-Input-SubText">
                                        <p>Stay up to date and be the first to find out about my upcoming events.</p>
                                    </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                </header>
            </div>
            </Col>
            </Row>
        )
    }
}

export default Banner;