import React from 'react';
import './Navbar.css';
import { Row, Col, Button, Drawer, Divider, Menu, Dropdown } from 'antd';
import { SocialIcon } from "react-social-icons";
import { MenuOutlined } from '@ant-design/icons';

class Navbar extends React.Component {
    state = {
        Visible: false,
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    render() {
        const color = this.props.Dark ? "black" : "white";
        return (
            <div>
            <Row justify="center" className="Navbar-Row">
            <Col xs={0} sm={23}>
                <Row justify="space-between" gutter={[10,10]}>
                    <Col>
                        <Row justify="space-around" gutter={[20,0]} align="middle">
                            <Col>
                                <a href="/" className="Navbar-Anchor-Link" style={{ color }}>Home</a>
                            </Col>
                            <Col>
                                <a href="#my-services" className="Navbar-Anchor-Link" style={{ color }}>My Services</a>
                            </Col>
                            <Col>
                                <a href="#about-me" className="Navbar-Anchor-Link"  style={{ color }}>About Me</a>
                            </Col>
                            <Col>
                                <a href="#testimonials" className="Navbar-Anchor-Link"  style={{ color }}>Testimonials</a>
                            </Col>
                            <Col>
                                <a href="/events" className="Navbar-Anchor-Link" style={{ color }}>Events</a>
                            </Col>
                            <Col>
                                <a href="/programs" className="Navbar-Anchor-Link"  style={{ color }}>Programs</a>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row justify="space-around" gutter={[20,0]} align="middle">
                            <Col>
                                <SocialIcon className="Navbar-Social-Media" fgColor="white" url="https://www.instagram.com/jiforcefitness/" />
                            </Col>
                            <Col>
                                <Button href="/#contact-me" className="Button-Red" size="large">Contact Me</Button>
                            </Col>
                            <Col>
                                <Button href="/login" className="Button-Black" size="large">Login & Sign Up</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            </Row>
            <Row justify="end">
                <Col xs={24} sm={0} style={{ textAlign: "right", marginRight: "10px" }}>
                    <Button icon={<MenuOutlined />} onClick={() => this.handleChange("Visible", true)} />
                </Col>
            </Row>
            <Drawer
                visible={this.state.Visible}
                onClose={() => this.handleChange("Visible", false)}
                width={300}>
                    <div className="Navbar-Drawer-Div">
                    <h1>JIFORCE FITNESS</h1>
                    <Divider />
                    </div>
                    <div className="Navbar-Drawer-Div">
                    <a href="/" className="Navbar-Anchor-Link-Small" onClick={() => this.handleChange("Visible", false)}>Home</a>
                    </div>
                    <div className="Navbar-Drawer-Div">
                    <a href="#my-services" className="Navbar-Anchor-Link-Small" onClick={() => this.handleChange("Visible", false)}>My Services</a>
                    </div>
                    <div className="Navbar-Drawer-Div">
                    <a href="#about-me" className="Navbar-Anchor-Link-Small" onClick={() => this.handleChange("Visible", false)}>About Me</a>
                    </div>
                    <div className="Navbar-Drawer-Div">
                    <a href="#testimonials" className="Navbar-Anchor-Link-Small" onClick={() => this.handleChange("Visible", false)}>Testimonials</a>
                    </div>
                    <div className="Navbar-Drawer-Div">
                    <a href="#contact-me" className="Navbar-Anchor-Link-Small" onClick={() => this.handleChange("Visible", false)}>Contact Me</a>
                    </div>
                    <div className="Navbar-Drawer-Div">
                    <a href="/events" className="Navbar-Anchor-Link-Small" onClick={() => this.handleChange("Visible", false)}>Events</a>
                    </div>
                    <div className="Navbar-Drawer-Div">
                    <a href="/programs" className="Navbar-Anchor-Link-Small" onClick={() => this.handleChange("Visible", false)}>Programs</a>
                    </div>
                    <Row justify='center'>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row justify='center'>
                        <Col>
                            <Button onClick={() => this.handleChange("Visible", false)} href="/login" className="Button-Black" size="large">Login & Sign Up</Button>
                        </Col>
                    </Row>
                </Drawer>
            </div>
        )
    }
}

export default Navbar;