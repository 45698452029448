import React from 'react';
import './Contact.css';
import { Row, Col, Input, Button, message, notification } from 'antd';
import axios from 'axios';
import { ENVIRONMENT } from '../../constants';

class Contact extends React.Component {
    state = {
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        Message: "",
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    validateEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
        }
        message.error("Please enter a valid email.")
        return (false)
    }

    validateTextInput = (text, name) => {
        if (String(text).length === 0) {
            message.error(`Please enter a ${name}.`)
            return false;
        } else {
            return true;
        }
    }

    submitContact = async (trainerEmail) => {
        const emailValidate = this.validateEmail(this.state.Email);
        if (!emailValidate) return;
        const nameValidate = this.validateTextInput(this.state.FirstName);
        if (!nameValidate) return;
        const data = {
            name: `${this.state.FirstName} ${this.state.LastName}`,
            email: this.state.Email,
            phone: this.state.PhoneNumber,
            message: this.state.Message,
            TrainerEmail: "josh_dsouza@hotmail.com",
        }
        const sendEmail = await axios.post(`${ENVIRONMENT.BACKEND_URL}/custom-website/contact`, data);
        if (sendEmail.data.error) {
            notification.error({
                message: "Something went wrong!",
                description: `Sorry we couldn't send your message, please send me an email at ${trainerEmail}`,
                duration: 0,
                placement: 'bottomRight',
            })
        } else {
            notification.success({
                message: "Success",
                description: "Thanks for your message, I'll be in contact with you soon.",
                placement: 'bottomRight',
            })
        }
    }

    render() {
        return (
            <Row justify="center" align="middle">
                <Col xs={23} sm={22}>
                    <Row>
                        <Col xs={24}>
                            <h1>Contact Me</h1>
                            <h3 style={{ fontWeight: "400" }}>Join me for a great workout whether you're throwing elbows, lifting weights, or sweating in a high intensity workout.</h3>
                        </Col>
                    </Row>
                    <Row gutter={[20,20]}>
                        <Col xs={24} sm={12}>
                            <Input placeholder="First Name" size="large" className="Contact-Input" value={this.state.FirstName} onChange={(e) => this.handleChange("FirstName", e.target.value)} />
                        </Col>
                        <Col xs={24} sm={12}>
                            <Input placeholder="Last Name" size="large" className="Contact-Input" value={this.state.LastName} onChange={(e) => this.handleChange("LastName", e.target.value)} />
                        </Col>
                        <Col xs={24} sm={12}>
                            <Input placeholder="Email" size="large" className="Contact-Input" value={this.state.Email} onChange={(e) => this.handleChange("Email", e.target.value)} />
                        </Col>
                        <Col xs={24} sm={12}>
                            <Input placeholder="Phone Number" size="large" className="Contact-Input" value={this.state.PhoneNumber} onChange={(e) => this.handleChange("PhoneNumber", e.target.value)} />
                        </Col>
                        <Col xs={24}>
                            <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Message" className="Contact-Input" size="large" value={this.state.Message} onChange={(e) => this.handleChange("Message", e.target.value)} />
                        </Col>
                        <Col xs={24} sm={5}>
                            <Button className="Button-Red" block size="large" onClick={() => this.submitContact("jiforcefitness@gmail.com")}>Submit</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Contact;