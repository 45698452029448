import React from 'react';
import {
    Banner,
    About,
    ReviewCard,
    ServiceCard,
    Contact,
    Footer,
} from '../../Components';
import './Landing.css';
import { Row, Col, Button } from 'antd';

class Landing extends React.Component {
    state = {
        MoreTestimonials: false,
    }
    constructor(props) {
        super(props);
        this.iFrameRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("message", (e) => {
            if (e.data?.hasOwnProperty("PhrosFrameHeight")) {
                console.log(e.data);
                document.getElementById("live-class-embed").style.height = e.data.PhrosFrameHeight + "px";
            }
        })
    }

    updateIFrameHeight = () => {
        const iframe = window.frames[0].frameElement;
        if (iframe) {
            iframe.height = "";
            iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
        }
    }

    render() {
        const cards = [
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-1.PNG?alt=media&token=9f8b1803-5d79-4772-b012-fd645dc398c3",
                ALT: "Jianna 1",
                // eslint-disable-next-line no-multi-str
                Review: "Jiana is a Phenomenal Personal Trainer !!!   She does not take a '1 program fits all' approach to working with you & your goals which I love !!! \
                I don't know how she does it - but NO session is the same, she changes things up regularly and knows when to push harder OR take it down a notch. \
                Really appreciate Jiana's brand - she is physically fit, enjoys the 'healthy' lifestyle and is constantly feeding her mind with positivity and challenge !!! \
                Jiana has made a real difference on the stimulation & enjoyment of working out !! Coming from an active person my whole lfe - I am EXTREMELY satisfied & look forward to every session.    Jiana is an awesome athlete & person - you will not go wrong !!! ",
                Reviewer: "Marylu Ferrante",
                Stars: 5,
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-1.PNG?alt=media&token=9f8b1803-5d79-4772-b012-fd645dc398c3",
                ALT: "Jianna 1",
                Review: "Jiana is a professional, motivating, talented and incredibly flexible trainer. When COVID restrictions forced us to stop meeting in person she made the switch to virtual training to help me continue improving my fitness journey, and to make things better the virtual training didn’t feel like I missed a step. She was also very accommodating to my schedule; I was able to organize times to have training sessions that didn’t interfere with routine daily life. Before training with Jiana I was struggling to meet the fitness requirement for the police academy, because of her I not only met those requirements but am currently thriving on my way to achieving my career goals. 10/10 would recommend.",
                Reviewer: "Izzy Sandiford",
                Stars: 5,
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-1.PNG?alt=media&token=9f8b1803-5d79-4772-b012-fd645dc398c3",
                ALT: "Jianna 1",
                Review: "I have been working with Jiana for just over 2 months now. I was nervous to get started because I have been inactive for over a year and she has been patient with the perfect balance between letting me listen to my body and pushing me to my max. What I appreciate most is that in almost every session she gives me accurate tweaks to my posture or form that change the game entirely! I avoid injury and get the most out of my work out. She inspires me weekly!",
                Reviewer: "Aria Zenua",
                Stars: 5,
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-1.PNG?alt=media&token=9f8b1803-5d79-4772-b012-fd645dc398c3",
                ALT: "Jianna 1",
                Review: "Working out with Ji is a highlight of my week. She’s enthusiastic, motivating, and knowledgeable about all things fitness. She knows how to push you just enough so that you always come back. Ji is very creative in progressing sessions, and coming up with weight free workouts especially during COVID times. She usually adds some Muay Thai stuff in as well which is always fun for me. No two sessions are ever the same!",
                Reviewer: "Adesola Adesinai",
                Stars: 5,
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-1.PNG?alt=media&token=9f8b1803-5d79-4772-b012-fd645dc398c3",
                ALT: "Jianna 1",
                Review: "I have attended a couple of Jiana’s workout classes and I can’t deny the level of fun you get when you go. The classes are usually full body and Jiana often acts as a guide along the way, reminding you to really activate and how to activate each muscle as you perform each exercise. She often comes ready to help you get the workout sweat you need for your day! I would recommend this class to anyone who’s looking for a great full body sweat.",
                Reviewer: "Jelena Milidragovic",
                Stars: 5,
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fjiana-5.PNG?alt=media&token=0e0407da-51f7-4229-a46a-172ab2ae0ba3",
                ALT: "Jianna 1",
                Review: "Jiana brings excitement and enthusiasm to her classes with unconventional exercises that are challenging and effective.",
                Reviewer: "Sandra B.",
                Stars: 5,
            },
        ]

        /*
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fone-on-one.jpg?alt=media&token=9a609b38-3728-4a76-92b3-3052403055fa",
                ALT: "Jiana 1",
                Title: "JiForce Personal Training",
                SubTitle: "Build Confidence, Improve Vitality, Feel Great",
                Description: "My personal training programs are geared to develop functional strength, improve stamina, and mobility through total body movements, unilateral patterns and resistance training. Cross-train with a variety of creative exercises that keep your fitness journey exciting.",
                ButtonLink: "https://app.jiforcefitness.com",
            },
        */
        const services = [
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fmuay-thai-2.jpg?alt=media&token=ea91449c-97e7-4c4a-b195-95446e558d26",
                ALT: "Jiana 1",
                Title: "Muay Thai",
                SubTitle: "Learn Muay Thai boxing techniques, practice striking combinations and unlock a new level of confidence.",
                Description: "Discover “the art of 8 limbs - punch, kick, elbow, knee, teep, clinch, and shadowbox in this fun, high-intensity martial art.",
                ButtonLink: "https://app.jiforcefitness.com",
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2FHIIT.jpg?alt=media&token=040515b8-f93a-45d1-a9b6-8053077cbca6",
                ALT: "Jiana 1",
                Title: "Ji-HIIT",
                SubTitle: "Build Stamina and Strength while Improving Mobility and Flexibility",
                Description: "My High Intensity Interval Training consists of core strengthening and explosive exercises, traditional and compound movements, and aspects of boxing.",
                ButtonLink: "https://app.jiforcefitness.com",
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fstrength-training.PNG?alt=media&token=c3f2c1ba-8a3e-4b78-b83b-49bd6571d00a",
                ALT: "Jiana 1",
                Title: "Ji-Strength",
                SubTitle: "Improve Overall Strength, Burn fat, Boost your Metabolism, Enhance your Bone and Joint Health ",
                Description: "My weights-based strength training program helps to build lean muscle and endurance.",
                ButtonLink: "https://app.jiforcefitness.com",
            },
            {
                SRC: "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fbootcamp.jpg?alt=media&token=5eb8e70a-9a67-436d-94d7-45cfc0e26d0d",
                ALT: "Jiana 1",
                Title: "JiForce Bootcamp",
                SubTitle: "Challenge yourself physically and mentally, push your limits through various training workstations. ",
                Description: "Change the scenery and enjoy the sun while we crush battle roping, sandbags and other intense activities designed to get your heart rate up.",
                ButtonLink: "https://app.jiforcefitness.com/bootcamps",
            },
        ]
        return (
            <div className="Landing-Main-Div">
                <div>
                <Banner />
                </div>
                <div id="about-me" className="Landing-Div-Space">
                <About />
                </div>

                <div className='Landing-Div-Space Landing-Main-Div-Spacing'>
                    <iframe
                        src="https://phros.ca/embed/as01UKfFiWa8PiFQ13xIX3OF9oh1/classes"
                        title="JiForceFitness Events"
                        frameBorder="0"
                        style={{
                            height: "600px",
                            width: "100%"
                        }}
                        id="live-class-embed"
                    >
                    </iframe>
                    </div>

                <div className="Landing-Div-Space">
                    <Row justify="center">
                        <Col xs={24} sm={22}>
                            <video width="100%" height="100%" id="Banner-Video" autoPlay muted controls loop>
                                <source
                                    src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/phros%2Fjiana-mp4-vid.mp4?alt=media&token=5fea5e8f-43b6-49f3-ac2f-e7910127152b"
                                    type="video/mp4"
                                />
                            </video>
                        </Col>
                    </Row>
                </div>
                <div className="Landing-Div-Space">
                    <Row justify="center">
                        <Col xs={22} sm={22}>
                        <Row justify="center">
                            <Col xs={24} sm={23}>
                            <h1>Pricing:</h1>
                            </Col>
                            <Col xs={24} sm={23}>
                                <Row>
                                    <Col xs={24} sm={12}>
                                    <p className="ServiceCard-Description">
                                        <strong>One on One:</strong> <br/>
                                        1 session: $70+ <br/>
                                        10 sessions: $650+ <br/>
                                        8 week program: $960+ <br/> <br/>
                                    </p>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                    <p className="ServiceCard-Description">
                                        <strong>Small Group training <i>(3 person minimum)</i>:</strong> <br/>
                                        1 session: $35+/pp <br/>
                                        10 sessions: $330+/pp <br/>
                                        8 week program: $480+/pp <br/>

                                        Note: 8 week program consists of 16 cross training sessions.
                                    </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={23}>
                            <a href="/#contact-me"><Button className="Button-Red" size="large">Contact Me</Button></a>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </div>

                <div id="my-services" className="Landing-Div-Space">
                    <Row justify="center" gutter={[0,30]}>
                        <Col xs={23} sm={22}>
                            <Row justify="space-around" align="middle" className="ServiceCard-Row">
                                <Col xs={24} sm={13}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fone-on-one.jpg?alt=media&token=9a609b38-3728-4a76-92b3-3052403055fa"
                                    alt="Jiana1" className="ServiceCard-Image" />
                                </Col>
                                <Col xs={24} sm={10}>
                                    <h2 className="ServiceCard-Title">JiForce Personal Training</h2>
                                    <h3 className="ServiceCard-SubTitle">Build Confidence, Improve Vitality, Feel Great</h3>
                                    <p className="ServiceCard-Description">
                                        My personal training programs are geared to develop functional strength, improve stamina, and mobility through total body movements, unilateral patterns and resistance training. Cross-train with a variety of creative exercises that keep your fitness journey exciting.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={22} sm={21}>
                            <h2>Cross Training Options:</h2>
                        </Col>
                        {
                            services.map((service) => (
                                <Col xs={23} sm={22}>
                                <ServiceCard service={service} />
                                </Col>
                            ))
                        }
                    </Row>
                </div>
                <div id="testimonials" className="Landing-Div-Space">
                    <Row justify="center">
                        <Col xs={23} sm={22}>
                            <h1>Testimonials</h1>
                        </Col>
                        <Col xs={23} sm={22}>
                        <Row gutter={[30,30]}>
                            {
                                cards.map((card, index) => {
                                    if (index < 3) {
                                        return (
                                            <Col xs={24} sm={8}><ReviewCard card={card} /></Col>
                                        )
                                    }
                                    if (index >= 3 && this.state.MoreTestimonials) {
                                        return (
                                            <Col xs={24} sm={8}><ReviewCard card={card} /></Col>
                                        )
                                    }
                                })
                            }
                        </Row>
                        </Col>
                        <Col xs={24} style={{ textAlign: "center" }}>
                            {
                                this.state.MoreTestimonials ?
                                <Button type="link" onClick={() => this.setState({ MoreTestimonials: false })}>Less</Button>
                                :
                                <Button type="link" onClick={() => this.setState({ MoreTestimonials: true })}>More Testimonials ...</Button>
                            }
                        </Col>
                    </Row>
                </div>
                <div id="contact-me" className="Landing-Div-Space">
                    <Contact />
                </div>
                <div className="Landing-Div-Space">
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Landing;