const SOCIAL_MEDIA = {
    INSTAGRAM: 'INSTAGRAM',
    FACEBOOK: 'FACEBOOK',
    TWITTER: 'TWITTER',
    PINTEREST: 'PINTEREST',
    LINKEDIN: 'LINKEDIN',
    YOUTUBE: 'YOUTUBE',
}

const environment = {
    DEV: {
        BACKEND_URL: "http://localhost:8080"
    },
    PROD: {
        BACKEND_URL: "https://us-central1-phros-dev-ddfdb.cloudfunctions.net/app"
    }
}

const UID = "";

module.exports = {
    SOCIAL_MEDIA,
    ENVIRONMENT: environment.PROD,
    UID,
}