import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { DataProvider } from './Context/Data';
import { Events, Landing, Portal, Programs } from './Pages';

function App() {
  return (
    <DataProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/programs" component={Programs} />
          <Route exact path="/login" component={Portal} />
          <Route exact path="/events" component={Events} />
        </Switch>
      </Router>
    </DataProvider>
  );
}

export default App;
