import React from 'react';
import {
    Footer,
    Navbar,
} from '../../Components';


const Events = () => {
    return (
        <div>
            <Navbar Dark={true} />

            <div style={{ marginTop: "30px", marginBottom: "200px", padding: "20px" }}>
            <iframe
                src="https://phros.ca/embed/as01UKfFiWa8PiFQ13xIX3OF9oh1/classes"
                title="JiForceFitness Events"
                frameBorder="0"
                style={{
                    height: "1000px",
                    width: "100%"
                }}
                id="live-class-embed"
            >
            </iframe>
            </div>


            <Footer />
        </div>
    )
}

export default Events;