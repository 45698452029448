import React, { useContext, useState, useEffect } from 'react';
import { ENVIRONMENT, UID } from '../constants';
import axios from 'axios';
import { Spin, Row, Col } from 'antd';

export const DataContext = React.createContext();

export function useData() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [profile, setProfileData] = useState(false);
    const [websiteData, setWebsiteData] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const payload = {
            uid: UID,
        }
        // Send Axios Post Request to get Data

        // Set the Data
        setLoading(false);
    })

    const value = {
        profile,
        websiteData,
    };

    return (
        <DataContext.Provider value={value}>
            {
                loading ?
                <Row justify='center' align='middle' style={{ height: '100vh' }}>
                    <Col>
                        <Spin size='large' />
                    </Col>
                </Row>
                :
                children
            }
        </DataContext.Provider>
    )
}