import React from 'react';
import './About.css';
import { Row, Col } from 'antd';

// I was born and raised in Damascus, Syria. From a young age I was very active and loved being outdoors – playing, running, climbing, jumping. As I grew older, this affinity to activity naturally flowed into athleticism and fitness. I was encouraged to participate in organized sports and played competitive basketball throughout my youth. Over my athletic career in Syria and later, at the University of Toronto Varsity team, I developed an unshakable determination to conquer my goals and a desire to continuously improve and grow. My involvement in sport instilled in me principles of hard work, dedication, teamwork, and perseverance. This drive eventually brought me to Muay Thai which has become my passion and my coaching profession. When I'm not hitting the pads, I love to hike, practice yoga, and of course, play basketball. With an internal fire and an open mind, I aim to try new experiences every passing year.
class About extends React.Component {
    render() {
        return (
            <Row justify="center" align='middle'>
            <Col xs={23} sm={22}>
                <Row justify="center" align="middle">
                    <Col xs={24} sm={0}>
                        <img className="About-Image"
                        src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fabout-me.jpg?alt=media&token=bfa0f594-ded8-480d-a732-830cc888c35a"
                        alt="Jiana Profile" />
                    </Col>
                    <Col xs={24} sm={14} className="About-Text-Col">
                        <h1 className="About-Title">Jiana Alnajjar</h1>
                        <h2 className="About-Subtitle">Fitness Instructor & Muay Thai Coach </h2>
                        <p className="About-Paragraph">
                        My passion for athletics, health, and mindfulness practices are the foundation of every fitness program I develop. The growth of my clients is the force that drives me, and I use my enthusiasm about fitness to encourage their development. I help my clients push their limits by providing them with the tools and techniques, specific to their body’s needs.
                        </p>
                    </Col>
                    <Col xs={0} sm={10}>
                        <img className="About-Image"
                        src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fjiana%2Fabout-me.jpg?alt=media&token=bfa0f594-ded8-480d-a732-830cc888c35a"
                        alt="Jiana Profile" />
                    </Col>
                </Row>
            </Col>
            </Row>
        )
    }
}

export default About;